
import { defineComponent, ref, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { useI18n } from "vue-i18n";
import { ApiDsSku } from "@/core/api";
import _ from "lodash";
import { MBDatatable } from "magicbean-saas-common";
import WindowResize from "@/components/layout/WindowResize.vue";
import FilterDropdown from "./FilterDropdown.vue";
import { useRoute, useRouter } from "vue-router";
import { setModuleBCN } from "@/core/directive/function/common";
import { DsOrderListItem } from "@/core/directive/interface/dsSkuManagement";
import { computed } from "vue";
import { NumberOrString } from "@/core/directive/type/common";
import {
  getDsOrderStatusClass,
  getDsOrderStatusText,
} from "@/core/directive/function/dsSkuManagement";

import { formatDate, formatDateTime } from "@/core/directive/function/common";

interface SortOption {
  field: string;
  direction: string;
}

interface FilterOption {
  field: string;
  value: string;
  from?: string;
  to?: number;
  condition?: string;
}

export default defineComponent({
  name: "products-ds-sku-management",
  props: {
    type: {
      type: Number,
      default: 0, // 0: sales order draft orders 1: double sale order draft orders
    },
  },
  components: {
    MBDatatable,
    WindowResize,
    FilterDropdown,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const loading = ref(true);
    const tableData = ref<Array<DsOrderListItem>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref("");
    const sortOptions = ref<Array<SortOption>>([
      {
        field: "created_at",
        direction: "desc",
      },
    ]);
    const filterOptions = ref<Array<FilterOption>>([]);
    const tableHeader = ref([
      {
        name: t("dsSkuManagement.orderID"),
        key: "orderID",
        sortable: false,
      },
      {
        name: t("dsSkuManagement.merchantName"),
        key: "merchantName",
        sortable: false,
      },
      {
        name: t("dsSkuManagement.date"),
        key: "created_at",
        sortable: false,
      },
      {
        name: t("dsSkuManagement.packageNumber"),
        key: "packageNumber",
        sortable: false,
      },
      {
        name: t("dsSkuManagement.SKUCount"),
        key: "SKUCount",
        sortable: false,
      },
      {
        name: t("dsSkuManagement.status"),
        key: "status",
        sortable: false,
      },
      {
        name: t("dsSkuManagement.actions"),
        key: "actions",
        sortable: false,
      },
    ]);

    /*
     *拉取数据
     */

    const getDsOrderList = (
      page: number,
      pageSize: number,
      search?: string,
      sortOptions?: Array<SortOption>,
      filterOptions?: Array<FilterOption>
    ) => {
      loading.value = true;
      ApiDsSku.getDsOrderList({
        page: page,
        page_size: pageSize,
        search_value: search,
        sort_orders: sortOptions,
        filter_group: filterOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    /*
     *状态显示
     */
    const dsSkuStatusClass = computed(() => {
      return (status: NumberOrString) => {
        return getDsOrderStatusClass(status);
      };
    });
    const dsSkuStatusText = computed(() => {
      return (status: NumberOrString) => {
        return getDsOrderStatusText(status);
      };
    });

    /*
     *搜索功能
     */
    const debounceSearch = _.debounce(getDsOrderList, 1000);
    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };
    const removeSearch = () => {
      search.value = "";
      updateList();
    };
    const updateList = () => {
      getDsOrderList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value
      );
    };

    /*
     *分页功能
     */
    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getDsOrderList(
        page,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };
    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getDsOrderList(
        currentPage.value,
        pagesize,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    /*
     *排序功能
     */
    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getDsOrderList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    /*
     *过滤功能
     */
    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "") {
          filterArr.push({
            field: item,
            value: val[item],
            condition: "like",
          });
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      getDsOrderList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };
    const handleFilterReset = () => {
      resetFilter();
      getDsOrderList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };
    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
      getDsOrderList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value
      );
    });

    return {
      t,
      dsSkuStatusClass,
      dsSkuStatusText,
      search,
      searchItems,
      removeSearch,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      handleFilter,
      handleFilterReset,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      getDsOrderList,
      formatDate,
      formatDateTime,
    };
  },
});
