
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

interface Filter {
  status: string | number;
  merchant_name: string;
}

export default defineComponent({
  name: "filter-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const formRef = ref<null | HTMLFormElement>(null);
    const data = ref<Filter>({
      status: "",
      merchant_name: "",
    });

    const submit = () => {
      emit("submit-filter", data.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    return {
      t,
      data,
      formRef,
      submit,
      handleReset,
    };
  },
});
